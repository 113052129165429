<template>
  <div>
    <v-app-bar
      id="home-app-bar"
      app
      elevation="1"
      elevate-on-scroll
      height="97"
    >
      <v-spacer />
      <v-toolbar-title
        class="white--text toolbarTitle mr-3 mb-1"
        style="color: #F2F2F2 !important;line-height: 37px;"
      >
        <v-card :to="{name: 'Home'}">
          {{ siteName }}
        </v-card>
      </v-toolbar-title>

      <!--      <base-img-->
      <!--        :src="require('@/assets/KSystems.png')"-->
      <!--        class=""-->
      <!--        contain-->
      <!--        max-width="157"-->
      <!--        width="100%"-->
      <!--      />-->

      <div>
        <v-tabs
          class="hidden-sm-and-down"
          optional
        >
          <v-tab
            v-for="(item, i) in items"
            :key="i"
            :to="item.to"
            :ripple="false"
            class="text-capitalize"
            style="font-weight: 500;font-size: 18px;"
            text
          >
            {{ item.name }}
          </v-tab>
        </v-tabs>
      </div>
<!--      <div v-if="isSwitchLangEnabled">-->
<!--        <div-->
<!--          v-for="(lang, index) in supportedLocales()"-->
<!--          :key="index"-->
<!--        >-->
      <v-btn-toggle v-model="toggle_lang">
          <v-btn
            v-if="isSwitchLangEnabled"
            style="font-weight: 500 !important; width: 0;min-width: 34px;height: 20px"
            @click="swithLang('ru')"
            class="btn_lang "
          >
            {{ 'Ru' }}
          </v-btn>
          <span
            v-if="isSwitchLangEnabled"
            style="padding-right: 3px;padding-left: 1px;"
           >
            {{'/'}}
          </span>
          <v-btn
            v-if="isSwitchLangEnabled"
            style="font-weight: 500 !important; width: 0;min-width: 34px;height: 20px"
            @click="swithLang('en')"
            class="btn_lang"
          >
            {{ 'En' }}
          </v-btn>
      </v-btn-toggle>
<!--        </div>-->
<!--      </div>-->
      <v-spacer />
            <v-app-bar-nav-icon
              class="hidden-md-and-up"
              @click="drawer = !drawer"
            />
    </v-app-bar>
    <v-navigation-drawer
            v-model="drawer"
            app
            color="black"
            temporary
            disable-resize-watcher
            width="100%"
    >
      <v-card-title
        horizontal
      >
        <v-card :to="{name: 'Home'}">
          {{ siteName }}
        </v-card>
        <v-spacer></v-spacer>
        <v-icon
                @click="drawer = !drawer"
        >mdi-close</v-icon>
      </v-card-title>
        <v-list
                dense
                nav
        >
          <v-list-item
                  v-for="(item, i) in items"
                  :key="i"
                  :to="item.to"
          >
            <v-list-item-content>
              <v-list-item-title>
                <base-subheading
                  :title="item.name"
                >
                </base-subheading>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
  </div>
</template>

<script>
  export default {
    name: 'HomeAppBar',
    data: () => ({
      drawer: false,
      siteName: 'KSystems',
      isSwitchLangEnabled: process.env.VUE_APP_MY_ENV_SWITCH_LANG === 'true',
      toggle_lang: [0],
      isActive: true,
      items: [
        {
          name: 'Services',
          to: '/#services',
        },
        {
          name: 'Stack',
          to: '/#stack',
        },
        {
          name: 'Our works',
          to: '/#our-works',
        },
        {
          name: 'Join us',
          to: '/join-us',
        },
        {
          name: 'Contact us',
          to: '/#contact-us',
        },
      ],
    }),
    methods: {
      swithLang (locale) {
        this.$i18n.locale = locale
        this.isActive = !this.isActive
      },
      supportedLocales () {
        const locales = require.context('../../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
        console.log('locales: ' + locales.keys())
        const localesName = []
        locales.keys().forEach(key => {
          const matched = key.match(/([A-Za-z0-9-_]+)\./i)
          if (matched && matched.length > 1) {
            const locale = matched[1]
            console.log(locale)
            localesName.push(locale)
          }
        })
        return localesName
      },
    },
  }
</script>

<style lang="sass">
  .toolbarTitle
    font-size: 30px !important
    font-weight: 500 !important
  .text_lang:active
    color: #0A8C4C !important
  .btn_lang
    border: transparent !important
    font-size: 18px !important
  .btn_lang.v-btn--active:hover::before, .btn_lang.v-btn--active::before
    border: 1px solid #000000 !important
    background: transparent !important
    font-weight: 700 !important
  .v-btn-toggle > .v-btn.v-btn
    opacity: 0.5 !important
  .v-btn-toggle > .v-btn.v-btn--active
    opacity: 1 !important
  #home-app-bar
    .v-tabs-slider
      max-width: 24px
      margin: 0 auto

    .v-tab
      &::before
        display: none
        font-size: 18px !important
</style>
